<template>
  <div>
    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>
    <div class="mb-8">
      <import-excel :onSuccess="loadDataInTable"/>
    </div>
    <vx-card>
      <!--Warehouses-->
      <div class="mt-5 mb-5">
        <label class="text-sm opacity-75">Almacén</label>
        <v-select label="name" :options="warehouses" :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="warehouse" class="mb-4 md:mb-0" name="warehouse"/>
      </div>
      <vs-table stripe pagination :max-items="10" search :data="tableData">
        <template slot="header">
          <h4>{{ sheetName }}</h4>
        </template>

        <template slot="thead">
          <vs-th :sort-key="heading" v-for="heading in header" :key="heading">{{ heading }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="col" v-for="(col, indexcol) in data[indextr]" :key="indexcol + col">
              {{ col }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-button @click="importData" class="mr-6 mt-5">Importar</vs-button>
    </vx-card>
  </div>
</template>

<script>
import ImportExcel from '@/components/excel/ImportExcel.vue'
import vSelect from 'vue-select'

import { auth, db, FieldValue } from '@/firebase/firebaseConfig'

export default {
  name: 'ImportStock',
  components: {
    ImportExcel,
    vSelect
  },
  data () {
    return {
      tableData: [],
      header: [],
      sheetName: '',
      initProgress: false,
      warehouses: [],
      products: [],
      warehouse: {}
    }
  },
  async created () {
    this.initProgress = true
    // Load warehouses
    let querySnapshot = await db.collection('warehouses').get()
    querySnapshot.forEach((doc) => {
      let obj = {
        id: doc.id,
        ...doc.data()
      }
      this.warehouses.push(obj)
    })
    // End
    this.initProgress = false
  },
  methods: {
    loadDataInTable ({ results, header, meta }) {
      this.header = header
      // Add state
      this.header.push('Estado')
      this.tableData = results

      this.tableData.forEach((d) => {
        this.$set(d, 'state', 'En espera')
      })
      this.sheetName = meta.sheetName
    },
    async importData () {
      try {
        if (this.warehouse.id) {
          this.initProgress = true
          for await (let p of this.tableData) {
            if (p.state === 'En espera') {
              await this.updateProductStock(p)
            }
          }
          if (!this.hasErrors) {
            this.$vs.notify({
              color: 'success',
              title: 'Carga de información',
              text: 'Actualización realizada correctamente.'
            })
          }
          else {
            this.$vs.notify({
              color: 'warning',
              title: 'Carga de información',
              text: 'Actualización completada con errores.'
            })
          }
          this.initProgress = false
        }
      } catch (e) {
        this.initProgress = false
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    },
    /**
     * Save data in firebase
     * @returns {Promise<void>}
     */
    async updateProductStock (p) {
      try {
        console.log(p)

        // Get product
        let product = {}
        const querySnap = await db
            .collection('products')
            .where('sku', '==', p['Sku'].toUpperCase())
            .limit(1).get()
        product = {
          ...querySnap.docs[0].data()
        }
        product.id = querySnap.docs[0].id
        // End

        // Validate if exists
        const querySnapValidate = await db
            .collection('products')
            .doc(product.id)
            .collection('movements')
            .where('document', '==', p.CPE)
            .get()
        // End

        if (querySnapValidate.docs.length === 0) {

          // Update stock
          const doc = await db.collection('products')
              .doc(product.id)
              .collection('stocks')
              .doc(this.warehouse.id).get()

          if (doc.exists) {
            doc.ref.update({
              stock: FieldValue.increment(p['Cantidad'] * -1)
            })
          }
          // End

          // Save movements
          await db.collection('products').doc(product.id).collection('movements').add({
            createdAt: FieldValue.serverTimestamp(),
            userName: auth.currentUser.displayName,
            quantity: p.Cantidad,
            oldStock: doc.exists ? doc.data().stock : 0,
            type: 'sale',
            id: 'NA',
            document: p.CPE,
            warehouse: {
              id: this.warehouse.id,
              name: this.warehouse.name
            },
            productId: product.id,
            cost: p['Precio unitario final'],
            purchasePrice: product['Costo'] || 0,
            discount: p['Descuento de venta unitario'],
            client: {},
            currency: {
              code: 'PEN'
            },
            motive: 'Venta'
          })
          p['Estado'] = 'Ok'
          // End
        }
      } catch (e) {
        console.log(e)
        this.hasErrors = true
        p.state = 'Error'
      }
    }
  }
}
</script>
